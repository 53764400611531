h1 {
  font-family: "EB Garamond", sans-serif;
  font-size: 2.3rem;
  font-weight: 500;
  font-style: normal;
  font-display: swap;
  align-self: center;
  color: #211915;
  margin: 0px;
  -webkit-user-select: text;
  -moz-user-select: text;
}

h2 {
  font-family: "Noto Sans TC", sans-serif;
  font-size: 0.93rem;
  font-weight: 500;
  font-style: normal;
  align-self: center;
  color: #211915;
  margin: 0px;
  -webkit-user-select: text;
  -moz-user-select: text;
}

section {
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  padding: 40px 20px;
  -webkit-tap-highlight-color: transparent;
}

footer {
  display: flex;
  box-sizing: border-box;
  align-items: stretch;
  height: 100px;
  background-color: #4b4c4d;
  padding: 0px 7%;
  -webkit-tap-highlight-color: transparent;
}

span {
  font-family: "Noto Sans TC", sans-serif;
  font-size: 0.95rem;
  font-weight: 400;
  font-style: normal;
  color: #3a393b;
  letter-spacing: 1px;
  -webkit-user-select: text;
  line-height: 1.5rem;
  -moz-user-select: text;
}

input {
  font-family: "Noto Sans TC", sans-serif;
  font-size: 0.93rem;
  font-weight: 500;
  font-style: normal;
  color: #3a393b;
  letter-spacing: 1px;
}

a {
  font-family: "Noto Sans TC", sans-serif;
  font-size: 0.93rem;
  font-weight: normal;
  font-style: normal;
  color: #3a393b;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}

.slick-slide,
.slick-slide * {
  outline: none !important;
}

.slick-dots {
  bottom: 50px;
}

.slick-dots li button:before {
  color: #ffffff;
}

.slick-dots li.slick-active button:before {
  color: #ffffff !important;
}

.pagination {
  padding: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: stretch;
}
.pagination > li {
  display: flex;
  list-style: none;
  border: none;
}

.pagination > li > a,
.pagination > li > span {
  display: block;
  margin: auto;
  padding: 6px 12px;
  text-align: center;
  color: #3A393B70;
  background-color: transparent;
  outline: none;
  font-size: 0.95rem;
}

/* Style the active class (and buttons on mouse-over) */
.pagination > li > a:hover {
  background-color:  transparent;
  color: #3A393B;
}

.pagination > li.active > a {
  font-size: 1.1rem;
  color: #EA4F15;
  background-color: transparent;
  outline: none;
}

.pagination > li:first-child > a,
.pagination > li:first-child > span {
  border-style: solid;
  border-width: 1px;
  border-color: #e0e0e0;
  border-radius: 3px;
  width: 20px;
  margin-left: 0;
  margin-right: 3px;
  padding: 6px 0px;
  outline: none;
}

.pagination > li:last-child > a,
.pagination > li:last-child > span {
  border-style: solid;
  border-width: 1px;
  border-color: #e0e0e0;
  border-radius: 3px;
  width: 20px;
  margin-right: 0;
  margin-left: 3px;
  padding: 6px 0px;
  outline: none;
}

.pagination > li.break > a {
  width: auto;
  padding: 0px 0px;
  color: #3A393B70;
  background-color: transparent;
  margin-left: -1px;
  outline: none;
  font-size: 0.95rem;
}